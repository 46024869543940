import styled from 'styled-components';
import { Button } from '@aws-amplify/ui-react';
import { FC } from 'react';

export const ButtonPrimary = styled(Button)`
  cursor: pointer;
  border-radius: 8px;
  border: 0px;
  background-color: var(--blue);
  color: #ffffff;
`;

export const ButtonSignUp = styled(Button)`
  border: 1.5px solid var(--blue);
  color: var(--blue);
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: var(--blue);
    color: var(--white);
  }
`;

export const ButtonSecondary: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Button borderRadius={10} border="1px solid #377DFF" color="#377DFF" width={'100%'}>
      {children}
    </Button>
  );
};
