import React from 'react';
import { Flex, Link as LinkAmplify, View, Grid } from '@aws-amplify/ui-react';
import styled from 'styled-components';
import Image from 'next/image';
import { Paragraph } from '@/components/Typography/Typography';

const Link = styled(LinkAmplify)`
  color: #ffffff;
  font-size: 16px;
  white-space: break-spaces;
  text-align: left;
  margin: 20px 0px;
  line-height: 26px;
`;

const Footer = () => {
  return (
    <>
      <Flex justifyContent="center" gap="0px" bottom={0} className="hidden md:block bottom-0">
        <View
          width="100%"
          height="100%"
          backgroundColor="#101820"
          padding={'32px 100px 12px 100px'}
          position="relative"
        >
          <Flex direction="row">
            <img src="/logo.svg" alt="AlphaLogoWhite" />
            <Paragraph style={{ borderLeft: '1px solid white' }} paddingLeft="15px" fontSize={14} color="#ffffff">
              Decoding data library into predictive analysis that create impactful business strategies.
            </Paragraph>
          </Flex>
          <Flex>
            <Paragraph fontSize={16} color="#ffffff">
              Wijaya Grand Centre, Jl. Wijaya II No.6, Blok E No.12 B, RT.5/RW.1, Pulo, Kec. Kby. Baru, Kota Jakarta
              Selatan, 12160
            </Paragraph>
          </Flex>
          <Flex direction="row" justifyContent="space-between" alignItems="center" marginTop={24}>
            <Flex direction="row">
              <Link href="/privacy-policy" color="#ffffff">
                Privacy Policy
              </Link>
              <Link href="/terms-and-condition" color="#ffffff">{`Terms & Condition`}</Link>
              <Link href="/about-us" color="#ffffff">
                About us
              </Link>
              <Link href="/contact-us" color="#ffffff">
                Contact
              </Link>
              {/* <Link href="/careers" color="#ffffff">
                Careers
              </Link> */}
            </Flex>
            <Flex direction="row" alignItems="center" gap={10}>
              <Flex direction="row" alignItems="center">
                <a
                  href="https://www.facebook.com/Alphalitical-100205812333727/?ref=pages_you_manage"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src="/images/socmed/facebook.svg" alt="Facebook" width={24} height={24} />
                </a>
                <a href="https://twitter.com/AlphaliticalDev" target="_blank" rel="noreferrer">
                  <Image src="/images/socmed/twitter.svg" alt="Twitter" width={24} height={24} />
                </a>
                <a href="https://www.instagram.com/alphalitical/" target="_blank" rel="noreferrer">
                  <Image src="/images/socmed/instagram.svg" alt="Instagram" width={24} height={24} />
                </a>
                <a href="https://www.linkedin.com/company/alphalitical/" target="_blank" rel="noreferrer">
                  <Image src="/images/socmed/linkedin.svg" alt="LinkedIn" width={24} height={24} />
                </a>
              </Flex>
            </Flex>
          </Flex>
          <View border="0.5px solid #63666A" marginTop={32} marginBottom={32} />
          <Paragraph textAlign="center" margin="0">
            © Copyright 2022 by Alphalitical.
          </Paragraph>
        </View>
      </Flex>
      {/* mobile design */}
      <Flex justifyContent="center" gap="0px" bottom={0} className="md:hidden">
        <View width="100%" height="100%" backgroundColor="#101820" padding={'32px 32px 12px 32px'} position="relative">
          <Flex direction="column">
            <img src="/logo.svg" alt="AlphaLogoWhite" />
            <Paragraph
              style={{ borderTop: '1px solid white' }}
              paddingLeft="15px"
              fontSize={14}
              color="#ffffff"
              textAlign="center"
            >
              Decoding data library into predictive analysis that create impactful business strategies.
            </Paragraph>
          </Flex>
          <Flex>
            <Paragraph fontSize={16} color="#ffffff" textAlign="center">
              Wijaya Grand Centre, Jl. Wijaya II No.6, Blok E No.12 B, RT.5/RW.1, Pulo, Kec. Kby. Baru, Kota Jakarta
              Selatan, 12160
            </Paragraph>
          </Flex>
          {/* <Grid
            // columnGap="0.5rem"
            // rowGap="0.5rem"
            templateColumns="auto"
            templateRows="auto">
            <Link href="/privacy-policy" color="#ffffff"
              marginBlock={10}>
              Privacy Policy
            </Link>
            <Link href="/terms-and-condition" color="#ffffff" marginBlock={10}>{`Terms & Condition`}</Link>
            <Link href="/about-us" color="#ffffff" marginBlock={10}>
              About us
            </Link>
            <Link href="/contact-us" color="#ffffff" marginBlock={10}>
              Contact
            </Link>
            <Link href="/careers" color="#ffffff" marginBlock={10}>
              Careers
            </Link>
          </Grid> */}
          <Flex direction="row" justifyContent="space-around" alignItems="center">
            <Link href="/privacy-policy" color="#ffffff" marginBlock={10}>
              Privacy Policy
            </Link>
            <Link href="/terms-and-condition" color="#ffffff" marginBlock={10}>{`Terms & Condition`}</Link>
          </Flex>
          <Flex direction="row" justifyContent="space-around" alignItems="center">
            <Link href="/about-us" color="#ffffff" marginBlock={10}>
              About us
            </Link>
            <Link href="/contact-us" color="#ffffff" marginBlock={10}>
              Contact
            </Link>
            {/* <Link href="/careers" color="#ffffff" marginBlock={10}>
              Careers
            </Link> */}
          </Flex>
          <Flex direction="row" justifyContent="space-between" alignItems="center" marginTop={24}>
            <Flex direction="row" justifyContent={'space-between'} width={'100%'}>
              <a
                href="https://www.facebook.com/Alphalitical-100205812333727/?ref=pages_you_manage"
                target="_blank"
                rel="noreferrer"
              >
                <Image src="/images/socmed/facebook.svg" alt="Facebook" width={24} height={24} />
              </a>
              <a href="https://twitter.com/AlphaliticalDev" target="_blank" rel="noreferrer">
                <Image src="/images/socmed/twitter.svg" alt="Twitter" width={24} height={24} />
              </a>
              <a href="https://www.instagram.com/alphalitical/" target="_blank" rel="noreferrer">
                <Image src="/images/socmed/instagram.svg" alt="Instagram" width={24} height={24} />
              </a>
              <a href="https://www.linkedin.com/company/alphalitical/" target="_blank" rel="noreferrer">
                <Image src="/images/socmed/linkedin.svg" alt="LinkedIn" width={24} height={24} />
              </a>
            </Flex>
          </Flex>
          <View border="0.5px solid #63666A" marginTop={32} marginBottom={32} />
          <Paragraph textAlign="center" margin="0">
            © Copyright 2022 by Alphalitical.
          </Paragraph>
        </View>
      </Flex>
    </>
  );
};

export default Footer;
