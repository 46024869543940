import '@/styles/global.css';
import type { AppProps } from 'next/app';

import 'react-toastify/dist/ReactToastify.css';
import { Navbar, NavbarMenu } from '../modules/Navbar';
import { Flex } from '@aws-amplify/ui-react';
import Link from 'next/link';
import Image from 'next/image';
import Head from 'next/head';
import Footer from 'modules/footer/Footer';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function MyApp({ Component, pageProps }: AppProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const menu = [
    { link: '/#home', label: 'Home', target: '_self' },
    { link: '/#features', label: 'Features', target: '_self' },
    { link: '/#whyAlphalitical', label: 'Why Alphalitical?', target: '_self' },
    { link: process.env.NEXT_PUBLIC_LOGIN_URL, label: 'Sign In', target: '_blank' },
    { link: process.env.NEXT_PUBLIC_LOGIN_URL + 'sign-up', label: 'Sign Up, Free!', target: '_blank' },
  ];

  const { asPath } = useRouter();
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head>
          <title>Alphalitical</title>
        </Head>
        <Navbar>
          <div className="flex md:flex-row items-center justify-between md:justify-normal w-full">
            <Link href="/#home">
              <Image src="/logo-color.svg" alt="Logo" width={230} height={40} />
            </Link>
            <div className="hidden md:block md:w-full">
              <NavbarMenu />
            </div>
            <div className="md:hidden">
              {isMenuOpen ? (
                <div>
                  <Image src="/X.svg" alt="Menu" width={20} height={20} onClick={() => setIsMenuOpen(false)} />
                </div>
              ) : (
                <Image src="/Burger.svg" alt="Menu" width={25} height={25} onClick={() => setIsMenuOpen(true)} />
              )}
            </div>
          </div>
          {/*
        <View>
          <Flex gap={24}>
            <Link href={process.env.NEXT_PUBLIC_LOGIN_URL}>
              <NavbarItem>Sign In</NavbarItem>
            </Link>
            <a href={process.env.NEXT_PUBLIC_LOGIN_URL + '/register'} style={{ all: 'unset' }}>
              <ButtonSignUp>Sign Up, Free!</ButtonSignUp>
            </a>
          </Flex>
        </View>
      */}
        </Navbar>
        {isMenuOpen ? (
          <div className="bg-white fixed top-0 left-0 pt-20 z-10 w-screen md:hidden">
            {menu.map((e) => (
              <Link
                target={e.target}
                href={e.link ?? ''}
                className={`${
                  asPath === e.link ? 'bg-[#f4f7fb] text-[#015dfe]' : ''
                } p-3 flex items-center justify-center mb-2 mx-[20px] rounded-md`}
                onClick={() => setIsMenuOpen(false)}
              >
                {e.label}
              </Link>
            ))}
          </div>
        ) : null}
        <main>
          <Component {...pageProps} />
        </main>

        <Footer />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
